


































































import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'

import Message from '@/components/ui/Message.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import Header from '@/components/Header.vue'

import UserForm from '@/components/commune/components/UserFormEdit.vue'
import * as check from '@/composables/commune'
import userAccountRequest from '@/composables/userAccountRequest'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

export default defineComponent({
  name: 'my-commune-edit-user',
  components: {
    Message,
    Breadcrumb,
    Header,
    UserForm,
    CustomLink,
    FeedbackMessage,
  },
  setup(_props, ctx) {
    const { $logger } = ctx.root
    const { i18n, userEdit } = useState('commune', ['i18n', 'userEdit'])
    const { SET_EDIT_USER } = useMutations('commune', ['SET_EDIT_USER'])
    const { chrome } = useState(['chrome'])
    const message = ref(true)
    const form = ref<HTMLFormElement>()
    const { currentLang } = useGetters(['currentLang'])
    const breadcrumbAdd = [
      {
        label: i18n.value.breadcrumb.users,
        url: `/${currentLang.value}/users/`,
      },
      { label: i18n.value.breadcrumb.usersEdit, url: '' },
    ]
    const isLoading = ref(false)
    const inputsValues = ref()
    const disableBtnSubmit = ref(false)
    const feedbackMessage = ref('')
    const hasError = ref(false)
    const submiting = ref(false)

    onBeforeMount(async () => {
      if (userEdit.value.length > 0) {
        isLoading.value = true
        const userFetch = await check.editPrefill(userEdit.value)
        inputsValues.value = userFetch
        isLoading.value = false
      } else {
        isLoading.value = true
        SET_EDIT_USER('')
        ctx.root.$router.push({ name: 'CommuneUsers' })
        isLoading.value = false
      }
    })

    onMounted(() => {
      $logger.debug('Users:edit:mounted')
    })

    ctx.root.$on('checkbox:selector:editUser', (roles: []) => {
      // console.log(inputsValues.value)
      inputsValues.value.Roles = roles
    })

    const onSubmit = async () => {
      disableBtnSubmit.value = true
      submiting.value = true
      const { error } = await userAccountRequest(
        `town/users/${userEdit.value}`,
        'patch',
        inputsValues.value
      )
      // eslint-disable-next-line no-negated-condition
      if (!error) {
        submiting.value = false
        hasError.value = false
        feedbackMessage.value = i18n.value.error.editConfirm
        SET_EDIT_USER('')
        ctx.root.$router.push({ name: 'CommuneUsers' })
      } else {
        disableBtnSubmit.value = false
        submiting.value = false
        hasError.value = true
        feedbackMessage.value = error.response.data.message
      }
    }
    const globalI18n = computed(() => chrome.value.data.i18n)

    return {
      message,
      i18n,
      isLoading,
      breadcrumbAdd,
      ...useGetters('user', ['isAdmin']),
      inputsValues,
      onSubmit,
      form,
      disableBtnSubmit,
      feedbackMessage,
      hasError,
      globalI18n,
      submiting,
    }
  },
})
